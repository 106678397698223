import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Typography } from '@mui/material';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

function Notification() {
  const [reminderEmail, setReminderEmail] = useState('true')
  const [confirmationEmail, setConfirmationEmail] = useState('true')
  const [promotionEmail, setPromotionEmail] = useState('true')
  const [shopId, setShopId] = useState('')
  const [customerId, setCustomerId] = useState('')

  const [data, setData] = useState(null);
  const [token, setToken] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);


  //handleSubmit

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramValue = queryParams.get('token');
    if (paramValue) {
      const data = jwtDecode(paramValue)
      const shopId = data?.shopId
      const customerId = data?._id
      if (!shopId && !customerId) {
        setPageLoading(false)
        setToken(true)
      }
      setShopId(shopId)
      setCustomerId(customerId)
    }
    else {
      setPageLoading(false)
      setToken(true)
    }
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `https://backendstaging.ripplrewards.com/api/notification/?shopId=${shopId}&customerId=${customerId}`,
          headers: {}
        };

        const response = await axios.request(config)
        if (response?.data?.status) {
          const data_ = response?.data?.data[0]
          setData(data_)
          setConfirmationEmail(typeof data_?.notificationData?.[0]?.confirmationEmail === 'boolean' ? data_?.notificationData[0]?.confirmationEmail : true)
          setPromotionEmail(typeof data_?.notificationData?.[0]?.promotionEmail === 'boolean' ? data_?.notificationData[0]?.promotionEmail : true)
          setReminderEmail(typeof data_?.notificationData?.[0]?.reminderEmail === 'boolean' ? data_?.notificationData[0]?.reminderEmail : true)
          setPageLoading(false)
        }
      } catch (error) {
        setError(error);
      } finally {
        setPageLoading(false);
      }
    };
    if (shopId && customerId)
      fetchData();
  }, [shopId, customerId]);

  useEffect(() => {
    if (data) {
      document.title = `Notification Preferences | ${data?.storeData} `;
    }
  }, [data]);

  const handleSubmit = async () => {
    setLoading(true)
    let data = JSON.stringify({
      reminderEmail, confirmationEmail, promotionEmail, shopId, customerId
    });

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `https://backendstaging.ripplrewards.com/api/notification/?cid=${customerId}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    const res = await axios.request(config)
    if (res?.data?.status) {
      toast.success('Notification update successfully.', {
        style: {
          fontFamily: 'Arial, sans-serif',
        },
        duration: 3000
      })
      setLoading(false)
    }
    else {
      toast.error('Something went wrong.')
      setLoading(false)
    }
  };
  if (pageLoading) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}><CircularProgress color='inherit' /></Box>
    )
  }

  return (
    <>
      {token ?
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'} >
          <Typography variant='h4' sx={{ fontWeight: 800, mb: 3, mt: 3 }}>Not a valid link!</Typography>
        </Box> :
        <Box pt={4} pb={4}  display={'flex'} justifyContent={'center'} flexDirection={'column'} >
          {data?.emailLogo ?
            <Box sx={{ width: '250px' }}>
              <img src={data?.emailLogo} alt={data?.storeData} />
            </Box> : null}
          <Box>
            <Typography variant='h4' sx={{ fontWeight: 800, mb: 3, mt: 3 }}>Notification preferences</Typography>
            <Typography variant='h5' sx={{ mb: 3 }}>{data?.email}</Typography>
            <Typography sx={{ mb: 3 }}>{`Looking to tailor your rewards experience? Uncheck the types of emails you no longer want to receive about your membership in the ${data?.programName ? data?.programName :  data?.storeData} rewards program.`}
            </Typography>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel sx={{ mb: 2 }} control={<Checkbox
                checked={reminderEmail}
                sx={{
                  color: '#000',
                  '&.Mui-checked': {
                    color: '#000',
                  },
                }}
              />}
                onChange={(e) => {
                  setReminderEmail(e.target.checked)
                }}
                label={<Box>
                  <Typography variant='h6'>Reminder emails</Typography>
                  <Typography>Sent when you have a reward to redeem or when a reward is close to expiring.</Typography>
                </Box>}
                />
              <FormControlLabel sx={{ mb: 2 }} control={<Checkbox
                checked={confirmationEmail}
                sx={{
                  color: '#000',
                  '&.Mui-checked': {
                    color: '#000',
                  },
                }} />
              }
                onChange={(e) => {
                  setConfirmationEmail(e.target.checked)
                }}
                label={<Box>
                  <Typography variant='h6'>Confirmation emails</Typography>
                  <Typography>Sent when you complete an action in the rewards program and receive a reward. (You won't be able to get your coupon codes/rewards if you unsubscribe to this 😊)</Typography>
                </Box>} />
              <FormControlLabel sx={{ mb: 2 }} control={<Checkbox
                checked={promotionEmail}
                sx={{
                  color: '#000',
                  '&.Mui-checked': {
                    color: '#000',
                  },
                }} />}
                onChange={(e) => {
                  setPromotionEmail(e.target.checked)
                }}
                label={<Box>
                  <Typography variant='h6'>Promotion emails</Typography>
                  <Typography>{`These are sent to inform you of exclusive promotions and ways to earn rewards.`}</Typography>
                </Box>} />
            </FormGroup>
            <Typography>{`Changing this setting will impact your preferences for other messages or mailing lists from ${data?.programName ? data?.programName :  data?.storeData} Please note -- many rewards are sent via email - so unsubscribing could impact your ability to receive your rewards.`}</Typography>
            <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'} mt={4}>
              <Button variant='contained' size='large'
                sx={{
                  color: '#fff', backgroundColor: '#000', fontWeight: 600,
                  minWidth: '220px',
                  height: '55px',
                  '&:hover': { // Define hover styles
                    backgroundColor: '#222222',
                  }
                }}
                onClick={handleSubmit}>
                {loading ? <CircularProgress color='inherit' size={22} /> : 'Save preferences'}

              </Button>
              {data?.primaryDomain ?
                <a href={`https://${data?.primaryDomain}`} rel="noreferrer" target='_blank' style={{ marginTop: '10px', color: '#000' }}>Visit Store</a> : null
              }
            </Box>
          </Box>
        </Box>}
    </>
  );

}
export default Notification;
